/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import styles from "./Checkout.module.css";
import Navbar from "../../components/Navbar/Navbar";
import { ThreeCircles } from "react-loader-spinner";
import ImageMockup from "../../components/Checkout/ImageMockup";
import UserDetails from "../../components/Checkout/UserDetails";

const PrintOrder = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Navbar />
      <div className={styles.contentWrapper}>
        {loading ? (
          <div className={styles.Loader}>
            <h4
              style={{
                marginTop: 220,
                marginBottom: 30,
              }}
              className="text-black"
            >
              Processing print order...
            </h4>
            <ThreeCircles
              height="100"
              width="100"
              color="#000"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        ) : (
          <div className="flex mt-[100px] sm:flex-col w-full justify-center">
            <ImageMockup />
            <UserDetails setLoading={setLoading}  paid={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PrintOrder;
