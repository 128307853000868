/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { imageStyles } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import skus from "../../data/shipping/sku.json";
import Thumbnails from "./components/Thumbnails";
import Image from "./components/Image";
import Frame from "./components/Frame";
import FrameSize from "./components/FrameSize";
import Mount from "./components/Mount";

const PrintOption = () => {
  const [gallery, setGallery] = useState("mockup");
  const [image, setImage] = useState("");
  const [aspectRatio, setAspectRatio] = useState("Square");
  const [sizing, setSizing] = useState(() =>
    aspectRatio === "Square" ? "12x12 / 30x30cm" : "59.4x84.1cm / 23.4x33.1 A1"
  );
  const [size, setSize] = useState(() =>
    aspectRatio === "Square" ? "30x30cm" : "A1"
  );
  const [color, setColor] = useState("black");
  const [mount, setMount] = useState("No-Mount");
  const [mockup, setMockup] = useState("");
  const [sku, setSKU] = useState("");

  useEffect(() => {
    let _data = window.localStorage.getItem("selectedImage");
    setImage(_data);
    let ratio = window.localStorage.getItem("AspectRatio");
    if (!ratio) return setAspectRatio("Square");
    if (
      ["square", "landscape", "portrait"].includes(ratio.toLocaleLowerCase())
    ) {
      setAspectRatio(ratio);
    } else {
      setAspectRatio("Square");
    }
  }, []);

  useEffect(() => {
    if (aspectRatio === "Square") {
      setSizing("12x12 / 30x30cm");
      setSize("30x30cm");
    } else {
      setSizing("59.4x84.1cm / 23.4x33.1 A1");
      setSize("A1");
    }
  }, [aspectRatio]);

  useEffect(() => {
    skus.map((item) => {
      if (item.Colour === color && item.Size === sizing && item.Mat === mount) {
        localStorage.setItem("sku", item.SKU);
        setSKU(item.SKU);
      }
      return 0;
    });

    localStorage.setItem("color", color);

    // Format data
    let sizeArr = sizing.split(" ");
    if (aspectRatio === "Square") {
      setSize(sizeArr[2]);
      setMockup(`${sizeArr[2]}-${color}-${mount}-${aspectRatio}`);
    } else {
      setSize(sizeArr[3]);

      setMockup(`${sizeArr[3]}-${color}-${mount}-${aspectRatio}`);
    }
  }, [sizing, color, mount, aspectRatio]);

  const proceedToCheckout = () => {
    const artData = {
      image,
      size,
      mount,
      mockup,
      color,
      aspectRatio,
    };
    localStorage.setItem("artData", JSON.stringify(artData));
    window.location.href = "/checkout";
  };

  return (
    <div>
      <Navbar />
      <div className="mt-[100px] w-full flex flex-col justify-center items-center">
        <div className="flex sm:flex-col w-[80%] justify-around mt-6">
          <Thumbnails
            gallery={gallery}
            setGallery={setGallery}
            mockup={mockup}
            image={image}
            aspectRatio={aspectRatio}
          />

          <Image
            gallery={gallery}
            image={image}
            mockup={mockup}
            imageStyles={imageStyles}
            size={size}
            mount={mount}
            aspectRatio={aspectRatio}
          />

          <div className="flex flex-col sm:w-[100%] sm:mt-[120px] w-[30%] md:px-6 text-black">
            <Frame color={color} setColor={setColor} />
            <FrameSize
              aspectRatio={aspectRatio}
              size={size}
              setSize={setSize}
              setSizing={setSizing}
            />
           <Mount mount={mount} setMount={setMount}/>

          <button
              onClick={proceedToCheckout}
              className="w-full h-[50px] border border-black rounded hover:bg-black hover:text-white sm:mb-6 sm:ml-[-30px] sm:w-[120%]"
            >
              Proceed To Checkout
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintOption;
