/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import styles from "./Styles.module.css";
import { UserContext } from "../../context/UserContext";
import { ThreeCircles } from "react-loader-spinner";
import { FaRegEdit } from "react-icons/fa";
import { Dialog } from "primereact/dialog";
import { toast } from "react-hot-toast";

const Prompt1 = ({ Loading }) => {
  const {
    dream,
    dreamAction,
    dreamLocation,
    dreamMode,
    artStyle,
    aspectRatio,
    setGeneratedPrompt,
    setDream,
    setDreamLocation,
    setDreamMode,
    setDreamAction,
    setArtStyle,
  } = useContext(UserContext);
  const [taggedPrompt, setTaggedPrompt] = useState([
    dream || " ",
    dreamAction || " ",
    dreamLocation || " ",
    dreamMode || " ",
    artStyle || " ",
  ]);
  const [index, setIndex] = useState(0);
  const [property, setProperty] = useState();
  const [visible, setVisible] = useState(false);

  const text = `${dream} ${dreamAction} in a ${dreamLocation} with a ${dreamMode} Colour Scheme in ${artStyle} style`;

  useEffect(() => {
    setGeneratedPrompt(text);
  }, [dream, dreamAction, dreamLocation, dreamMode, artStyle, aspectRatio]);

  useEffect(() => {
    setDream(taggedPrompt[0]);
    setDreamAction(taggedPrompt[1]);
    setDreamLocation(taggedPrompt[2]);
    setDreamMode(taggedPrompt[3]);
    setArtStyle(taggedPrompt[4]);
  }, [taggedPrompt]);

  const handleEdit = (e) => {
    e.preventDefault();
    if (index === 0) setDream(property);
    if (index === 1) setDreamAction(property);
    if (index === 2) setDreamLocation(property);
    if (index === 3) setDreamMode(property);
    if (index === 4) setArtStyle(property);
    taggedPrompt[index] = property;
    setTaggedPrompt(taggedPrompt);
    setVisible(false);
  };

  const handleShow = (property, index) => {
    setIndex(index);
    setProperty(property);
    setVisible(true);
  };
  return (
    <>
      {Loading ? (
        <div className={styles.Loader}>
          <h3>Generating Image...</h3>
          <div>
            <ThreeCircles
              height="100"
              width="100"
              color="#000"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          <div>
            <p className="text-[30px] sm:text-[20px]  mt-[10px] sm font-bold">
              Prompt Generated
            </p>
          </div>
          <div className="flex w-full justify-center items-center mt-5">
            <div className="bg-white w-[40%] sm:w-[100%] min-h-[40px] rounded p-2 flex justify-around items-center flex-wrap">
              {taggedPrompt.map((item, index) => (
                <div className="bg-[#EDF2F7] mb-2 min-w-[10px] rounded h-[10px] flex justify-center items-center p-3">
                  <div className="mr-2">{item}</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => handleShow(item, index)}
                  >
                    <FaRegEdit />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Dialog
        header="Edit prompt"
        visible={visible}
        className="p-4 rounded h-[23vh] sm:h-[33vh] w-[20%] sm:w-[80%] bg-[#fff]"
        onHide={() => {
          if (!property) {
            toast.error("Property cannot be empty");
          } else {
            setVisible(false);
          }
        }}
      >
        <form onSubmit={handleEdit} className="p-2 mt-3">
          <input
            type="text"
            className="w-[100%] rounded p-2 outline-none mb-4 border"
            placeholder="Enter property"
            value={property}
            onChange={(e) => setProperty(e.target.value)}
            required
          />

          <button className="border border-black p-2 rounded hover:bg-black hover:text-white w-[100%]">
            Save
          </button>
        </form>
      </Dialog>
    </>
  );
};

export default Prompt1;
