import React from "react";
import "./mockup.css";
import mockups from "../../../assets/mockup";
const Image = ({
  gallery,
  image,
  mockup,
  imageStyles,
  size,
  mount,
  aspectRatio,
  width,
}) => {
  return (
    <div
      className={`${
        width ? "w-[100%]" : "w-[50%] "
      } h-[60vh] sm:w-[100%] sm:h-[30vh]`}
    >
      {gallery === "image" ? (
        <div className="flex w-full h-full flex justify-center items-center">
          <img
            src={image}
            alt=""
            className={
              aspectRatio === "Square"
                ? `h-[400px] sm:h-[230px] w-[400px] sm:w-[230px] sm:mt-5 rounded`
                : aspectRatio === "Landscape"
                ? "h-[150px] sm:h-[50px] w-full sm:w-[100px] rounded"
                : "h-[40vh] sm:h-[150px]  sm:w-[100px] rounded"
            }
          />
        </div>
      ) : (
        <div className="c-container">
          <img
            src={mockups[`${mockup}`]}
            className="background rounded"
            alt=""
          />
          <div className="image">
            <img
              src={image}
              style={{
                ...imageStyles[`${size}-${mount}-${aspectRatio}`],
                position: "absolute",
                transform: "translate(-50%, -50%)",
              }}
              alt=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Image;
