import React from "react";

const Frame = ({ color, setColor }) => {
  return (
    <div className="mb-3">
      <p className="h6 text-left font-medium text-black  sm:ml-[-27px]">
        Frame Color
      </p>
      <div className="flex sm:flex-wrap sm:ml-[-30px] w-[130%]">
        <div
          style={{
            border: color === "natural" ? "solid 2px black" : "solid 1px #ccc",
            borderRadius: "10px",
          }}
          onClick={() => {
            setColor("natural");
          }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
        >
          <img
            src="/assets/natural.png"
            className="w-full h-full rounded"
            alt=""
          />
        </div>
        <div
          style={{
            border: color === "black" ? "solid 2px black" : "solid 1px #ccc",
            borderRadius: "10px",
          }}
          onClick={() => {
            setColor("black");
          }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
        >
          <img
            src="/assets/black.png"
            className="w-full h-full rounded"
            alt=""
          />
        </div>
        <div
          style={{
            border: color === "white" ? "solid 2px black" : "solid 1px #ccc",
            borderRadius: "10px",
          }}
          onClick={() => {
            setColor("white");
          }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
        >
          <img
            src="/assets/white.png"
            className="w-full h-full rounded"
            alt=""
          />
        </div>
        <div className="text-black text-xs sm:hidden bg-tranparent h-[90px] sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center">
          <img
            src="/assets/white.png"
            className="w-full h-full hidden rounded"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Frame;
