/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import styles from "../../pages/checkout/Checkout.module.css";
import Stripe from "../../components/stripe/Stripe";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { getQuote } from "../../store/reducers/prodigi";
import { getPrice } from "../../store/reducers/strpe";

const MakePayment = ({ setDone }) => {
  const dispatch = useDispatch();
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [shippingMethod, setShippingMethod] = useState("Standard");
  const [sku, setSKU] = useState("");
  const [color, setColor] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [copies, setCopies] = useState("1");
  const [cost, setCost] = useState(0);
  const [printCost, setPrintCost] = useState(0);
  const [issues, setIssues] = useState(null);
  const [charge, setCharge] = useState(0);

  useEffect(() => {
    let _color = localStorage.getItem("color");
    setColor(_color);
    let code = localStorage.getItem("country_code");
    setCountryCode(code);
  }, []);

  useEffect(() => {
    dispatch(getPrice()).then((res) => {
      setCharge(res.payload.settings[1].price);
    });
    let _sku = localStorage.getItem("sku");
    setSKU(_sku);
  }, []);

  useEffect(() => {
    if (shippingMethod && copies && countryCode.length === 2) {
      setQuoteLoading(true);
      window.localStorage.setItem(
        "shipping",
        JSON.stringify({ shippingMethod, copies, cost })
      );
      dispatch(
        getQuote({
          shippingMethod,
          copies: +copies,
          countryCode,
          sku,
          color,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error("An error occured");
          return;
        }
        if (!res.payload.quote) {
          toast.error("Unsupported SKU, please try another");
          setCost(0);
          setQuoteLoading(false);
        }
        if (res.payload.issues) {
          setIssues(res.quote.issues);
        }
        setPrintCost(
          Number(res.payload.quote.quotes[0]?.costSummary.totalCost.amount)
        );
        setCost(
          Number(res.payload.quote.quotes[0]?.costSummary.totalCost.amount) +
            (+charge / 100) *
              Number(res.payload.quote.quotes[0]?.costSummary.totalCost.amount)
        );
        setQuoteLoading(false);
      });
    }
  }, [shippingMethod, copies, countryCode, sku, charge]);

  return (
    <div className="h-[85vh] sm:w-[100%] sm:p-5 md:mt-[120px] sm:mt-[140px] sm:mt-[10px] xs:mt-[40px]">
      {/* <div className="ml-[-280px] text-black mt-2" onClick={() => setDone(false)}>Back</div> */}
      <div
        className={`${styles.form} bg-white p-6 rounded text-left text-black`}
      >
        <h3>Make Payment</h3>
        {issues && <p>{issues}</p>}
        <div className={styles.inputDiv}>
          <label>Copies</label>
          <input
            type="number"
            className="border text-black"
            placeholder="e.g 1"
            value={copies}
            onChange={(e) => setCopies(e.target.value)}
            required
          />
        </div>

        {/* <div className={styles.inputDiv}>
        <div>
          <label>Country Code</label>
          <input
            type="text"
            className="border text-black"
            placeholder="Country Code"
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            required
          />
        </div>
      </div> */}
        <div className={styles.inputDiv}>
          <label>Shipping Method</label> <br />
          <select
            className="border text-black w-full rounded p-2"
            onChange={(e) => setShippingMethod(e.target.value)}
            value={shippingMethod}
            required
          >
            <option value="standard">Standard</option>
            <option value="express">Express</option>
            {/* <option value="overnight">Overnight</option> */}
            {/* <option value="budget">Budget</option> */}
          </select>
        </div>
        {quoteLoading ? (
          <div className={styles.Loader}>
            <ThreeDots
              visible={true}
              height="40"
              width="40"
              color="#ccc"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <h4 className="mt-3">Total: $ {cost.toFixed(2)}</h4>
        )}
        {cost > 0 && (
          <Stripe
            amount={cost}
            copies={copies}
            shippingMethod={shippingMethod}
            quoteLoading={quoteLoading}
            countryCode={countryCode}
            charge={(+charge / 100) * printCost}
          />
        )}
      </div>
    </div>
  );
};

export default MakePayment;
