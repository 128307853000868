/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import styles from "../../pages/checkout/Checkout.module.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { printOrder } from "../../store/reducers/prodigi";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

const UserDetails = ({ paid, setDone, setLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userEmail } = useContext(UserContext);
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [shippingMethod, setShippingMethod] = useState("Standard");
  const [postalOrZipCode, setPostalOrZipCode] = useState("");
  const [sku, setSKU] = useState("");
  const [color, setColor] = useState("");
  const [stateOrCounty, setStateOrCounty] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [townOrCity, setTownOrCity] = useState("");
  const [copies, setCopies] = useState("1");
  const [sizing] = useState("fillPrintArea");
  const [cost, setCost] = useState(0);
  const [charge, setCharge] = useState(0);
  const [image, setImage] = useState("");
  const [jobId, setJobId] = useState(null);
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    if (paid) {
      setTimeout(() => {
        toast.success("Payment successful!");
      }, 2000);
    }
    let _color = localStorage.getItem("color");
    setColor(_color);
    getCountryAlpha2Codes();
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        // Process the retrieved data
        setTownOrCity(data.city);
        setAddress1(`${data.country_name}, ${data.city}`);
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.log("Erro r:", error);
      });
  }, []);

  const handleCountry = (data) => {
    window.localStorage.setItem("country_code", data.code);
    setStateOrCounty(data.name);
    setCountryCode(data.code);
  };

  useEffect(() => {
    let payload = window.localStorage.getItem("dreamsumi");
    setJobId(JSON.parse(payload).jobid);

    let _sku = localStorage.getItem("sku");
    setSKU(_sku);

    let _data = window.localStorage.getItem("selectedImage");
    setImage(_data.split("?")[0]);

    let order = JSON.parse(localStorage.getItem("dreamsumi_order_data"));
    setCopies(order?.copies);
    setShippingMethod(order?.shippingMethod);
    setCost(order?.cost);
    setCharge(order?.charge);
  }, []);

  async function getCountryAlpha2Codes() {
    try {
      const response = await axios.get("https://restcountries.com/v2/all");
      const countries = response.data;

      const countryCodes = countries.map((country) => {
        return {
          name: country.name,
          code: country.alpha2Code,
        };
      });
      setCodes(countryCodes);
      setCountryCode(countryCodes[0].code);
    } catch (error) {
      console.error("Error retrieving country alpha-2 codes:", error);
      return [];
    }
  }

  const handlePrint = async (e) => {
    if (e) e.preventDefault();
    try {
      if (!paid) {
        if (
          name &&
          address1 &&
          address2 &&
          postalOrZipCode &&
          townOrCity &&
          countryCode &&
          stateOrCounty
        ) {
          if (countryCode.length > 3) {
            toast.error("Invalid country code");
            return;
          }
          localStorage.setItem(
            "printOptions",
            JSON.stringify({
              name,
              jobId,
              address1,
              address2,
              postalOrZipCode,
              sizing,
              email: userEmail,
              image,
              cost,
              charge,
              townOrCity,
              countryCode,
              stateOrCounty,
              sku,
              color,
            })
          );
          return setDone(true);
        } else {
          toast.error("All fields are required");
          return;
        }
      }

      setLoading(true);
      let result = localStorage.getItem("printOptions");
      result = JSON.parse(result);
      let shipping = localStorage.getItem("shipping");
      shipping = JSON.parse(shipping);
      const data = { ...result, ...shipping };
      await dispatch(printOrder(data)).then((res) => {
        if (res.meta.requestStatus === "rejected") {
          toast.error("An error occured");
          setLoading(false);
          return;
        }
        if (!res.payload._result) {
          setLoading(false);
          return toast.error("Something went wrong!");
        }
        localStorage.removeItem("dreamsumi_order_data");
        toast.success("Operation completed successfully!");
        setLoading(false);
        navigate("/order-complete");
        return;
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      return;
    }
  };

  return (
    <div className="h-[85vh] sm:w-[100%] sm:p-5 md:mt-[120px] sm:mt-[140px] sm:mt-[10px] xs:mt-[20px]">
      <div
        className={`${styles.form} bg-white p-6 rounded text-left text-black`}
      >
        <h3>{paid ? "Submit Print Order" : "User Details"}</h3>
        {paid ? (
          <div>
            <button
              className="flex mt-3 justify-center w-full items-center border border-black rounded py-2 hover:bg-black hover:text-white"
              onClick={handlePrint}
            >
              Submit Print Order
            </button>
          </div>
        ) : (
          <form onSubmit={handlePrint}>
            <div className={styles.inputDiv}>
              <label>Full Name</label>
              <input
                className="border text-black"
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className={styles.inputDiv}>
              <label>Address</label>
              <input
                type="text"
                className="border text-black"
                placeholder="Address 2"
                value={address1}
                onChange={(e) => {
                  setAddress1(e.target.value);
                  setAddress2(e.target.value);
                }}
                required
              />
            </div>
            {/* <div className={styles.inputDiv}>
              <label>Address 2</label>
              <input
                type="text"
                className="border text-black"
                placeholder="Address 2"
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                required
              />
            </div> */}
            <div className={styles.inputDiv2}>
              <div className="w-[20px]">
                <label>Country</label>
                <select
                  onChange={(e) => handleCountry(JSON.parse(e.target.value))}
                  className="border p-2 rounded"
                >
                  {codes.map((code, i) => (
                    <option key={i} value={JSON.stringify(code)}>
                      {code.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>Zip or Postal Code</label>
                <input
                  type="number"
                  className="border text-black"
                  placeholder="e.g 123"
                  value={postalOrZipCode}
                  onChange={(e) => setPostalOrZipCode(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className={styles.inputDiv}>
              <label>Town or City</label>
              <input
                type="text"
                className="border text-black"
                placeholder="Town or City"
                value={townOrCity}
                onChange={(e) => setTownOrCity(e.target.value)}
                required
              />
            </div>
            {/* <div className={styles.inputDiv}>
              <label>Country Code</label>

              <input
                type="text"
                className="border text-black"
                placeholder="Country Code"
                disabled={true}
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              />
            </div> */}
            <button
              className="flex mt-3 justify-center w-full items-center border border-black rounded py-2 hover:bg-black hover:text-white"
              onClick={handlePrint}
            >
              Next
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
