import React, { useState } from "react";
import styles from "./Gallery.module.css";
import { useNavigate } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
const slideLeft = keyframes`
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
`;
const MainGalleryLeft = styled.div`
  display: flex;
  animation: ${(props) =>
    props.isPaused
      ? "none"
      : css`
          ${slideLeft} 150s linear infinite
        `};
`;

function GalleryLeft({ images, height }) {
  const navigate = useNavigate();

  const [isPaused, setIsPaused] = useState(false);

  // Function to pause the slider animation
  const pauseSlider = () => {
    setIsPaused(true);
  };

  // Function to resume the slider animation
  const resumeSlider = () => {
    setIsPaused(false);
  };

  const chooseImage = (item) => {
    window.localStorage.setItem("selectedImage", item.image);
    localStorage.setItem("AspectRatio", item.aspectRatio);
    navigate("/print-options");
  };
  return (
    <div className={styles.galleryContainer}>
      <MainGalleryLeft
        isPaused={isPaused}
        onMouseOver={pauseSlider}
        onMouseOut={resumeSlider}
      >
        {images.map((item, index) => (
          <div
            key={index}
            className={`${styles.imageContainer} h-[${height}px]`}
            style={{
              backgroundImage: `url(${
                item.image.doc ? item.image.doc : item.image
              })`,
              animationDelay: `${index * 0.2}s`,
            }}
            onClick={() => chooseImage(item)}
          >
            <div className={styles.overlay}>
              {/* <p>{item.art_style}</p> */}
            </div>
          </div>
        ))}
      </MainGalleryLeft>
    </div>
  );
}

export default GalleryLeft;
