export const imageStyles = {
  "A4-2.4mm-Landscape": {
    width: "13%",
    height: "8%",
    left: "52%",
    top: "30%",
  },
  "A4-No-Mount-Landscape": {
    width: "16.6%",
    height: "11.7%",
    left: "51.8%",
    top: "29.8%",
  },
  "A3-2.4mm-Landscape": {
    width: "20%",
    height: "12%",
    left: "52%",
    top: "29.8%",
  },
  "A3-No-Mount-Landscape": {
    width: "23.3%",
    height: "16.8%",
    left: "51.9%",
    top: "29.8%",
  },
  "A2-2.4mm-Landscape": {
    width: "27.3%",
    height: "18%",
    left: "52%",
    top: "29.8%",
  },
  "A2-No-Mount-Landscape": {
    width: "33%",
    height: "23.1%",
    left: "51.7%",
    top: "29.8%",
  },

  "A1-2.4mm-Landscape": {
    width: "40.7%",
    height: "28%",
    left: "51.8%",
    top: "29.8%",
  },
  "A1-No-Mount-Landscape": {
    width: "47%",
    height: "32.7%",
    left: "51.8%",
    top: "29.8%",
  },

  // Portrait
  "A4-2.4mm-Portrait": {
    width: "7.5%",
    height: "12.5%",
    left: "51.8%",
    top: "30%",
  },
  "A4-No-Mount-Portrait": {
    width: "11.8%",
    height: "16.3%",
    left: "51.8%",
    top: "29.8%",
  },
  "A3-2.4mm-Portrait": {
    width: "12%",
    height: "18.8%",
    left: "51.8%",
    top: "29.8%",
  },
  "A3-No-Mount-Portrait": {
    width: "16.5%",
    height: "23%",
    left: "51.8%",
    top: "29.8%",
  },
  "A2-2.4mm-Portrait": {
    width: "17.3%",
    height: "28.5%",
    left: "51.7%",
    top: "29.8%",
  },
  "A2-No-Mount-Portrait": {
    width: "23.1%",
    height: "32.7%",
    left: "51.7%",
    top: "29.8%",
  },
  "A1-2.4mm-Portrait": {
    width: "27.3%",
    height: "41%",
    left: "51.7%",
    top: "29.8%",
  },
  "A1-No-Mount-Portrait": {
    width: "32.9%",
    height: "46.7%",
    left: "51.7%",
    top: "29.8%",
  },

  // Square
  "30x30cm-2.4mm-Square": {
    width: "14%",
    height: "12.5%",
    left: "51.8%",
    top: "29.8%",
  },
  "30x30cm-No-Mount-Square": {
    width: "17%",
    height: "16.8%",
    left: "51.7%",
    top: "29.8%",
  },
  "40x40cm-2.4mm-Square": {
    width: "19%",
    height: "18.4%",
    left: "51.7%",
    top: "29.3%",
  },
  "40x40cm-No-Mount-Square": {
    width: "23%",
    height: "23%",
    left: "51.7%",
    top: "29.5%",
  },
  "50x50cm-2.4mm-Square": {
    width: "23%",
    height: "23%",
    left: "51.7%",
    top: "29.4%",
  },
  "50x50cm-No-Mount-Square": {
    width: "28.3%",
    height: "28.3%",
    left: "51.7%",
    top: "29.8%",
  },
  "60x60cm-2.4mm-Square": {
    width: "27%",
    height: "27%",
    left: "51.7%",
    top: "29.4%",
  },
  "60x60cm-No-Mount-Square": {
    width: "33.4%",
    height: "33.4%",
    left: "51.7%",
    top: "29.8%",
  },
};
