/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/user`

const VerifyEmail = () => {
  const { token } = useParams();
  const [loading, setloading] = useState(false);
  const verifyEmail = async () => {
    axios.get(`${url}/verification/${token}`).then(() => setloading(false));
  };

  useEffect(() => {
    verifyEmail()
  }, []);

  return (
    <div className="flex flex-col h-[100vh] justify-center items-center text-black">
      {loading ? (
        <div>
          <ThreeCircles
            height="100"
            width="100"
            color="#fff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      ) : (
        <div className="flex flex-col h-[100vh] justify-center items-center text-black">
          <div className="bg-white rounded-full p-6 mb-2">
            <img src="/assets/verify_email.png" width={200} alt="" />
          </div>
          <p> You have successfully verified your email !</p>
          <Link
            to="/login"
            className="text-decoration-none text-white bg-black p-2 rounded"
          >
            Proceed to login
          </Link>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
