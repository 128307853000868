import React, { useContext } from "react";
import styles from "./Styles.module.css";
import { UserContext } from "../../context/UserContext";
import Gallery from "../Gallery/Gallery";

const Prompt1 = ({handleKeyPress}) => {
  const { dreamMode, setDreamMode } = useContext(UserContext);

  return (
    <div className={styles.content}>
      <div>
        <p className="text-[30px] sm:text-[17px] mt-[10px] sm font-bold">Is there a mood/feeling or color pallet suits your vision?</p>
      </div>
      <div className={styles.inputDiv}>
        <input
          type="text"
          placeholder="Enter ..."
          value={dreamMode}
          onChange={(e) => setDreamMode(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
      <div className={`${styles.gallery} mt-[20px]`}>
        <Gallery show="mood" />
      </div>
    </div>
  );
};

export default Prompt1;
