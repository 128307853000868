import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../layouts/AdminLayout";
import axios from "axios";
import toast from "react-hot-toast";

const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;
const token = JSON.parse(localStorage.getItem("dreamsumiai-admin"));

const Images = ({ itemsPerPage = 7 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);



  // Filter the data based on the search term
  const filteredData = images.filter((item) =>
    item.artStyle?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get the current page of data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getImages = async () => {
    setFetchLoading(true);
    const res = await axios.get(`${admin_url}/get_carousel`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    setImages(res.data.images);
    setFetchLoading(false);
  };

  const deleteImage = async (id) => {
    try {
      if (loading) return;
      setLoading(true);
      console.log(id);
      const { data } = await axios.delete(`${admin_url}/delete_carousel_image/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (data.success) {
        toast.success(data.message);
        getImages();
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Operation unsuccessful");
      setLoading(false);
    }
  };

  useEffect(() => {
    getImages();
  }, []);
  return (
    <AdminLayout>
      <div className="p-4 justify-start">
        <table className="w-full">
          <thead className="bg-gray-200">
            <tr className="flex justify-between">
              <th className="py-2  w-[15%]">Image</th>
              <th className="py-2  w-[15%]">Prompt</th>
              <th className="py-2  w-[15%]">Art Style</th>
              <th className="py-2  w-[15%]">Action</th>
            </tr>
          </thead>
          {fetchLoading ? (
            "Loading..."
          ) : (
            <tbody>
              {currentData.map((item) => (
                <tr
                  className="flex justify-between items-center hover:bg-[#eee] cursor-pointer"
                  key={item._id}
                >
                  <td className="py-2 w-[15%]">
                  
                    <img
                      src={item.image}
                      alt=""
                      className="ml-5"
                      width={50}
                      height={50}
                      srcset=""
                    />
                  </td>
                  <td className="py-2 w-[15%] ">
                    {item.prompt ? item.prompt.slice(0, 12) : "No prompt"}
                  </td>
                  <td className="py-2 w-[15%]">{item.artStyle}</td>
                  <td className="py-2 w-[15%] hover:text-red-400">
                    <button
                      className="ml-2 text-red-500"
                      onClick={() => deleteImage(item._id)}
                    >
                      {loading ? "Loading..." : "Remove"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div className="flex justify-center w-[100%] overflow-scroll mt-4">
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={pageNumber === currentPage}
                className={`px-4 rounded py-2 mx-1 cursor-pointer ${
                  pageNumber === currentPage
                    ? " border-1 border-black text-black"
                    : "bg-black opacity-[0.3] text-white"
                }`}
              >
                {pageNumber}
              </button>
            )
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Images;
