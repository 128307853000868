import React, { useState, useEffect } from "react";
import { imageStyles } from "../../pages/printOptions/styles";
import mockups from "../../assets/mockup";

const ImageMockup = () => {
  const [artData, setArtData] = useState(null);
  useEffect(() => {
    let artData = localStorage.getItem("artData");
    setArtData(JSON.parse(artData));
  }, []);
  
  return (
    <div className="w-[40%] sm:w-[100%] px-6 h-[60vh] sm:mb-6">
      <div className="h-full sm:h-[450px] xs:h-[300px]  w-full flex justify-center items-center position-relative bg-cover bg-no-repeat">
        <img
          src={mockups[`${artData?.mockup.split('.p')[0]}`]}
          style={{
            width: "100%",
            height: "101%",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "10px",
          }}
          alt=""
        />
        <img
          src={artData?.image}
          style={{
            ...imageStyles[
              `${artData?.size}-${artData?.mount}-${artData?.aspectRatio}`
            ],
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
          alt=""
        />
      </div>
      <div className="mt-3 flex flex-col justify-center w-full">
        <p className="h3 text-black text-left mb-2">Your Selections</p>
        <div className="flex w-full">
          <div
            style={{
              border: "solid 2px black",
              borderRadius: "10px",
            }}
            className="text-black text-xs mr-4 bg-white h-[90px] rounded cursor-pointer w-[90px] sm:h-[100px] flex justify-center items-center md:w-[80px] sm:w-[100px]"
          >
            <img
              src={`${
                artData?.mount === "2.4mm"
                  ? "/assets/mount.png"
                  : "/assets/no-mount.png"
              }`}
              className="w-full h-full rounded"
              alt=""
            />
          </div>
          <div
            style={{
              border: "solid 2px black",
              borderRadius: "10px",
            }}
            className="text-black text-xs mr-4  bg-white h-[90px] rounded cursor-pointer w-[90px] sm:h-[100px] flex justify-center items-center md:w-[80px] sm:w-[100px]"
          >
            <img
              src={`/assets/${artData?.color}.png`}
              className="w-full h-full rounded"
              alt=""
            />
          </div>
          <div
            style={{
              border: "solid 2px black",
              borderRadius: "10px",
            }}
            className="text-black text-xs  bg-white h-[90px] rounded cursor-pointer w-[90px] sm:h-[100px] flex justify-center items-center md:w-[80px] sm:w-[100px]"
          >
            <p className="mt-3">{artData?.size}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageMockup;
