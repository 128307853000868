import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoImages, IoPeople, IoSettings } from "react-icons/io5";

const AdminLayout = ({ children }) => {
  const location = useLocation();

  const logOut = () => {
    window.localStorage.removeItem("dreamsumiai-admin");
    window.location.replace("/admin");
  };
  return (
    <div className="flex w-full">
      <div className="w-[15%] h-[100vh] p-3">
        <p className="text-[20px] font-bold text-black">Dreamsumi Admin</p>
        <div className="py-[30px]">
          <Link to="/users" className="no-underline text-black">
            <div
              className={`hover:bg-black w-[100%]  py-1 px-3 rounded hover:text-white mb-3 flex items-center  ${
                location.pathname.includes("users") && "bg-black text-white"
              }`}
            >
              <IoPeople />
              <span className="ml-1"> Users</span>
            </div>
          </Link>
          <Link to="/admins" className="no-underline text-black">
            <div
              className={`hover:bg-black w-[100%]  py-1 px-3 rounded hover:text-white mb-3 flex items-center  ${
                location.pathname.includes("admins") && "bg-black text-white"
              }`}
            >
              <IoPeople />
              <span className="ml-1"> Admins</span>
            </div>
          </Link>
          <Link to="/images" className="no-underline text-black">
            <div
              className={`hover:bg-black w-[100%]  py-1 px-3 rounded hover:text-white mb-3 flex items-center  ${
                location.pathname.includes("images") && "bg-black text-white"
              }`}
            >
              <IoImages />
              <span className="ml-1">Images</span>
            </div>
          </Link>
          <Link to="/carousel" className="no-underline text-black">
            <div
              className={`hover:bg-black w-[100%]  py-1 px-3 rounded hover:text-white mb-3 flex items-center  ${
                location.pathname.includes("carousel") && "bg-black text-white"
              }`}
            >
              <IoImages />
              <span className="ml-1">Carousel</span>
            </div>
          </Link>
          <Link to="/settings" className="no-underline text-black">
            <div
              className={`hover:bg-black w-[100%] py-1 px-3 rounded hover:text-white mb-3 flex items-center ${
                location.pathname.includes("settings") && "bg-black text-white"
              }`}
            >
              <IoSettings />
              <span className="ml-1">Settings</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="w-[85%] flex flex-col">
        <div className="w-full h-[60px] flex justify-between items-center px-5 py-2">
          {location.pathname.includes("users") && (
            <p className="text-[20px] font-bold text-black mt-3 h3">Users</p>
          )}
          {location.pathname.includes("admins") && (
            <p className="text-[20px] font-bold text-black mt-3 h3">Admins</p>
          )}
          {location.pathname.includes("images") && (
            <p className="text-[20px] font-bold text-black mt-3 h3">Images</p>
          )}
          {location.pathname.includes("settings") && (
            <p className="text-[20px] font-bold text-black mt-3 h3">Settings</p>
          )}
          <button
            onClick={logOut}
            className="border border-black p-2 rounded h-[40px] hover:bg-black text-black"
          >
            <p className="hover:text-white text-sm">Log Out</p>
          </button>
        </div>
        <div className="bg-white h-[90vh] w-[97%] text-black p-5 rounded">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
