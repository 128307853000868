import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../layouts/AdminLayout";
import axios from "axios";
import { CiCirclePlus } from "react-icons/ci";
import { Dialog } from "primereact/dialog";
import toast from "react-hot-toast";
const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;
const token = JSON.parse(localStorage.getItem("dreamsumiai-admin"));

const Admins = ({ itemsPerPage = 10 }) => {
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("active");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [admins, setAdmins] = useState([]);

  // Filter the data based on the search term
  const filteredData = admins.filter((item) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get the current page of data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search term change
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when search term changes
  };

  const getAdmins = async () => {
    setFetchLoading(true);
    const res = await axios.get(`${admin_url}/get_admins`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    setAdmins(res.data.admins);
    setFetchLoading(false);
  };

  const handleCreateAdmins = (event) => {
    event.preventDefault();
    if (password !== cPassword) return toast.error("Passwords do not match");
    setLoading(true);

    // Send formData to the server using AJAX or fetch API
    // Example using fetch:
    axios
      .post(
        `${admin_url}/signup`,
        {
          password,
          email,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async ({ data }) => {
        // Handle the response from the server
        if (data.success) {
          toast.success("Operation successfully");
          await getAdmins();
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.success("Operation unsuccessfully");
        setLoading(false);
      });
  };

  const handleDeleteAdmin = (item) => {
    if (item.isSuperAdmin) {
      return toast.error("Super admin account cannot be deleted!");
    }
    setLoading(true);
    // Send formData to the server using AJAX or fetch API
    // Example using fetch:
    axios
      .delete(`${admin_url}/delete/${item._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        // Handle the response from the server
        if (data.success) {
          toast.success("Operation successfully");
          await getAdmins();
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.success("Operation unsuccessfully");
        setLoading(false);
      });
  };

  const handleAdminStatus = (item) => {
    if (item.isSuperAdmin) {
      return toast.error("Super admin account status cannot be changed!");
    }
    setLoading(true);
    axios
      .put(
        `${admin_url}/update_status/${item._id}`,
        {
          status: item.status === "active" ? "inactive" : "active",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async ({ data }) => {
        // Handle the response from the server
        if (data.success) {
          toast.success("Operation successfully");
          await getAdmins();
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.success("Operation unsuccessfully");
        setLoading(false);
      });
  };
  useEffect(() => {
    getAdmins();
  }, []);
  return (
    <AdminLayout>
      <div className="p-4 justify-start">
        <div className="flex items-center justify-between">
          <input
            type="text"
            placeholder="Search Art style"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="w-[20%] p-2 mb-4 border border-gray-300 rounded outline-none mr-[1000px]"
          />
          <button
            onClick={() => setVisible(true)}
            className="border p-2 mb-4 rounded hover:bg-black hover:text-white ml-[-1030px] mt-4 w-[20%] flex items-center justify-center"
          >
            <CiCirclePlus />
            <span className="ml-2"> Add Admin</span>
          </button>
        </div>
        <table className="w-full">
          <thead className="bg-gray-200">
            <tr className="flex justify-between">
              <th className="py-2 px-2">Email</th>
              <th className="py-2 px-2">Status</th>
              <th className="py-2 px-2">Super Admin</th>
              <th className="py-2 px-2">Email Verified</th>
              <th className="py-2 px-2">Action</th>
            </tr>
          </thead>
          {fetchLoading ? (
            "Loading..."
          ) : (
            <tbody>
              {currentData.map((item) => (
                <tr
                  className="flex justify-between hover:bg-[#eee] cursor-pointer"
                  key={item._id}
                >
                  <td className="py-2 px-2 w-[2%]">{item.email}</td>
                  <td
                    className={`py-2 px-2 w-[38%] left-[-20px] relative ${
                      item.status === "active"
                        ? "text-green-400"
                        : "text-orange-400"
                    }`}
                  >
                    {item.status.toUpperCase()}
                  </td>
                  <td className="py-2 px-2 w-[10%] left-[-100px] relative">
                    {String(item?.isSuperAdmin).toUpperCase()}
                  </td>
                  <td className="py-2 px-2 w-[10%] ml-[150px] left-[-80px] relative">
                    {item.emailConfirmed ? "Verified" : "Not Verified"}
                  </td>
                  <td className="py-2 px-2 text-xs flex">
                    {loading ? (
                      "Loading..."
                    ) : (
                      <>
                        <div
                          onClick={() => handleAdminStatus(item)}
                          className={`${
                            item.status === "active"
                              ? "text-orange-400"
                              : "text-green-400"
                          } hover:font-bold cursor-pointer`}
                        >
                          {item.status === "active" ? "Deactivate" : "Activate"}
                        </div>

                        <div
                          onClick={() => handleDeleteAdmin(item)}
                          className="text-red-400 cursor-pointer ml-2 hover:font-bold"
                        >
                          Delete
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={pageNumber === currentPage}
                className={`px-4 rounded py-2 mx-1 cursor-pointer ${
                  pageNumber === currentPage
                    ? "bg-[#F0E1CB] text-white"
                    : "bg-gray-300 text-gray-700"
                }`}
              >
                {pageNumber}
              </button>
            )
          )}
        </div>
        <Dialog
          header="Add Admin"
          visible={visible}
          className="p-5 rounded h-[60vh] w-[50%] bg-[#eee]"
          onHide={() => setVisible(false)}
        >
          <form onSubmit={handleCreateAdmins} className="p-2 mt-3">
            <input
              type="email"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Confirm Password"
              value={cPassword}
              onChange={(e) => setCPassword(e.target.value)}
              required
            />
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-[100%] rounded p-2 outline-none mb-4"
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>

            <button className="border border-black p-2 rounded hover:bg-black hover:text-white w-[100%]">
              {loading ? "Loading..." : "Submit"}
            </button>
          </form>
        </Dialog>
      </div>
    </AdminLayout>
  );
};

export default Admins;
