import { Navigate } from "react-router-dom";

const PaymentProtected = ({ children }) => {
  let order = JSON.parse(localStorage.getItem("dreamsumi_order_data"));
  if (!order?.paid || !order) {
    return <Navigate to="/checkout" replace />;
  }
  return <>{children}</>;
};

export default PaymentProtected;
