import React, { useContext } from "react";
import styles from "./Styles.module.css";
import { UserContext } from "../../context/UserContext";
import Gallery from "../Gallery/Gallery";

const Prompt1 = ({ handleKeyPress }) => {
  const { artStyle, setArtStyle } = useContext(UserContext);

  return (
    <div className={styles.content}>
      <div className="mt-[-100px] sm:mt-[10px]">
        <p className="text-[30px] sm:text-[20px] font-bold">Choose Art Style</p>
      </div>
      <div className={styles.inputDiv}>
        <input
          type="text"
          placeholder="Enter ..."
          value={artStyle}
          onChange={(e) => setArtStyle(e.target.value)}
          onKeyDown={handleKeyPress}
        />
      </div>
      <div className={`${styles.gallery} mt-[20px]`}>
        <Gallery show="artStyle" />
      </div>
      {/* <div className={styles.artStyles}>
        {artStyles.map((style, index) => (
          <div
            onClick={() => setArtStyle(style.label)}
            key={index}
            style={{
              background: artStyle === style.label ? "black" : "transparent",
              color: artStyle === style.label ? "white" : "black",
            }}
            className={styles.artStyle}
          >
            {style.label}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Prompt1;
