import React from 'react'

const PromptIndicator = ({count}) => {
  return (
    <div className="mt-[180px] w-full flex flex-col justify-center items-center sm:hidden">
    <div className="w-[80%] flex  justify-around ">
      <div className="flex justify-center items-center flex-col">
        <div
          style={{
            backgroundColor: count >= 0 ? "black" : "transparent",
            color: count >= 0 ? "white" : "black",
          }}
          className="border rounded-full border-black h-[40px] w-[40px] flex justify-center items-center mb-2"
        >
          1
        </div>
        <p>Art Style</p>
      </div>

      <div className="flex justify-center items-center flex-col">
        <div
          style={{
            backgroundColor: count >= 1 ? "black" : "transparent",
            color: count >= 1 ? "white" : "black",
          }}
          className="border rounded-full border-black h-[40px] w-[40px] flex justify-center items-center mb-2"
        >
          2
        </div>
        <p>Subject</p>
      </div>
      <div className="flex justify-center items-center flex-col">
        <div
          style={{
            backgroundColor: count >= 2 ? "black" : "transparent",
            color: count >= 2 ? "white" : "black",
          }}
          className="border rounded-full border-black h-[40px] w-[40px] flex justify-center items-center mb-2"
        >
          3
        </div>
        <p>Location</p>
      </div>
      <div className="flex justify-center items-center flex-col">
        <div
          style={{
            backgroundColor: count >= 3 ? "black" : "transparent",
            color: count >= 3 ? "white" : "black",
          }}
          className="border rounded-full border-black h-[40px] w-[40px] flex justify-center items-center mb-2"
        >
          4
        </div>
        <p>Action</p>
      </div>
      <div className="flex justify-center items-center flex-col">
        <div
          style={{
            backgroundColor: count >= 4 ? "black" : "transparent",
            color: count >= 4 ? "white" : "black",
          }}
          className="border rounded-full border-black h-[40px] w-[40px] flex justify-center items-center mb-2"
        >
          5
        </div>
        <p>Mood</p>
      </div>
      <div className="flex justify-center items-center flex-col">
        <div
          style={{
            backgroundColor: count >= 5 ? "black" : "transparent",
            color: count >= 5 ? "white" : "black",
          }}
          className="border rounded-full border-black h-[40px] w-[40px] flex justify-center items-center mb-2"
        >
          6
        </div>
        <p>Aspect Ratio</p>
      </div>
    </div>
    <div className="h-[10px] bg-[#bbb] w-[80%] rounded-lg">
      <div
        style={{
          width: `${((count + 1) / 7) * 100}%`,
        }}
        className={`bg-black h-full rounded-lg`}
      ></div>
    </div>
  </div>
  )
}

export default PromptIndicator