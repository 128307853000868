/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./Table.module.css";
import { useDispatch } from "react-redux";
import { getOrders } from "../../store/reducers/prodigi";
import { ThreeCircles } from "react-loader-spinner";
import { formatDate } from "../../helpers/formatDate";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function TableComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filter the data based on the search term
  const filteredData = orders.filter((item) =>
    item.shipments[0]?.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get the current page of data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search term change
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when search term changes
  };

  const getOrdersHandler = async () => {
    try {
      setLoading(true);
      await dispatch(getOrders()).then((res) => {
        console.log(res.payload);
        setOrders(res.payload.orders);
        setLoading(false);
        return;
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrdersHandler();
  }, []);

  const viewDetails = (item) => {
    if (item.shipments[0]?.status === "Shipped") {
      navigate(`/order/${item.id}`);
      return;
    } else {
      toast.error("Please this order is still processing!");
    }
    return;
  };

  return (
    <div className={styles.tableContainer}>
      {loading ? (
        <div className={`${styles.loader} mt-[200px]`}>
          <ThreeCircles
            height="100"
            width="100"
            color="#000"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </div>
      ) : (
        <div>
          <input
            type="text"
            placeholder="Filter by status..."
            className={`${styles.filterInput} rounded`}
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <table className={styles.table}>
            <thead>
              <tr>
                {/* <th>No</th> */}
                <th>Image</th>
                <th>Order</th>
                <th>Copies</th>
                {/* <th>Total</th> */}
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {!searchTerm && orders?.length > 0 ? (
                currentData.map((item, index) => {
                  return (
                    <tr
                      className={styles.rowEven}
                      key={index}
                      onClick={() => viewDetails(item)}
                    >
                      {/* <td>{item.id}</td> */}
                      <td>
                        <img
                          src={
                            item?.items[0].thumbnailUrl ||
                            "/assets/demo/dreamsumi.jpeg"
                          }
                          style={{ width: 40, height: 40 }}
                          alt=""
                        />
                      </td>
                      <td>{item.id}</td>
                      <td className="ml-4">{item.items[0]?.copies}</td>
                      {/* <td>$ {item.items[0]?.recipientCost?.amount}</td> */}
                      <td
                        style={{
                          color:
                            item.shipments.length &&
                            item.shipments[0]?.status === "Shipped"
                              ? "green"
                              : "orange",
                        }}
                      >
                        {item.shipments[0]?.status || "In Production"}
                        {/* {
                          item.shipments[0]?.status === "Shipped" && <p className="ml-2">View</p>
                        } */}
                      </td>
                      <td>{formatDate(item.created)}</td>
                    </tr>
                  );
                })
              ) : (
                <tr style={{ textAlign: "center" }}>
                  <td
                    colSpan={8}
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    No Orders Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <div className={`flex justify-center w-[100%] overflow-scroll mt-4 ${styles.pagination}`}>
            {Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  disabled={pageNumber === currentPage}
                  className={`px-4 rounded py-2 mx-1 cursor-pointer ${
                    pageNumber === currentPage
                      ? " border-1 border-black text-black"
                      : "bg-black opacity-[0.3] text-white"
                  }`}
                >
                  {pageNumber}
                </button>
              )
            )}
          </div> */}
        </div>
      )}
    </div>
  );
}

export default TableComponent;
