/* eslint-disable react-hooks/exhaustive-deps */
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { getStripeSecret } from "../../store/reducers/strpe";
import { useDispatch } from "react-redux";

const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_PK));

const Checkout = ({
  amount,
  quoteLoading,
  copies,
  shippingMethod,
  countryCode,
  charge,
}) => {
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    dispatch(getStripeSecret(amount)).then((res) => {
      setClientSecret(res?.payload?.clientSecret);
    });
  }, [amount]);

  const options = {
    mode: "payment",
    amount: Math.round(+amount * 100) ,
    currency: "usd",
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            quoteLoading={quoteLoading}
            copies={copies}
            shippingMethod={shippingMethod}
            cost={amount}
            countryCode={countryCode}
            charge={charge}
            clientSecret={clientSecret}
          />
        </Elements>
      )}
    </div>
  );
};

export default Checkout;

function CheckoutForm({
  quoteLoading,
  copies,
  shippingMethod,
  countryCode,
  cost,
  charge,
  clientSecret,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    localStorage.setItem(
      "dreamsumi_order_data",
      JSON.stringify({
        paid: true,
        copies,
        shippingMethod,
        cost: +cost,
        charge,
        countryCode,
      })
    );

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setMessage(submitError.message);
      return setIsLoading(false);
    }

    const { error: confirmError } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${process.env.REACT_APP_REDIRECT_URI}/print-order`,
      },
    });
    if (
      confirmError.type === "card_error" ||
      confirmError.type === "validation_error"
    ) {
      setMessage(confirmError.message);
      return setIsLoading(false);
    }

    localStorage.setItem(
      "dreamsumi_order_data",
      JSON.stringify({
        paid: false,
        copies,
        shippingMethod,
        cost: +cost,
        charge,
      })
    );
    return setIsLoading(false);
  };
// +234 814 561 9724
  return (
    <form id="payment-form" className="w-full" onSubmit={handleSubmit}>
      <PaymentElement />
      {quoteLoading ? (
        <div className="flex justify-content-center align-items-center mt-5">
          <ThreeDots
            visible={true}
            height="40"
            width="40"
            color="#bbb"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className="flex justify-center w-full items-center border border-black rounded py-2 hover:bg-black hover:text-white"
          style={{
            marginTop: 20,
          }}
        >
          <span id="button-text">
            {isLoading ? (
              <ThreeDots
                visible={true}
                height="40"
                width="40"
                color="#bbb"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Pay now"
            )}
          </span>
        </button>
      )}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
