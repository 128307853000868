import React, { useEffect, useState } from "react";
import styles from "./Howitworks.module.css";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Gallery from "../../components/Gallery/Gallery";
import step1 from "../../assets/Dreamsumi1.png"
import step2 from "../../assets/Dreamsumi2.png"
import step3 from "../../assets/Dreamsumi3.png"
import { CiFacebook, CiLinkedin } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import axios from "axios";

const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;

const Howitworks = () => {
  const [images, setImages] = useState([]);
  
  const getImages = async () => {
  try {
    const res = await axios.get(`${admin_url}/get_images`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    setImages(res.data.images);
  } catch (error) {
    console.log(error);
  }
  };
  useEffect(() => {
    getImages();
  }, []);
  return (
    <div className="text-black">
      <Navbar />
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.getStarted}>
            <Link to={"/"}>Get Started</Link>
          </div>
          <h3>Get started and create your own art to frame</h3>
          <div className={`${styles.sectionDiv} flex-wrap-reverse`}>
            <div className={styles.imageDiv}>
              <img src={step1} alt="" />
            </div>
            <div className={`${styles.contentLeft} p-4`}>
              <h3>Step 1</h3>
              <p>
                Experience the thrill of diving into our platform, where endless
                fun awaits. Unleash your creativity and craft a captivating
                picture that truly captures your essence. Let your imagination
                soar as you design an image that speaks volumes and reflects
                your unique perspective.
              </p>
            </div>
          </div>
          <div className={`${styles.sectionDiv} flex-wrap`}>
            <div className={styles.contentLeft}>
              <h3>Step 2</h3>
              <p>
                Choose the optimal print size for your magnificent creation, and
                watch it come alive on exquisite museum-quality fine art paper,
                meticulously crafted with archival inks to ensure its longevity
                and brilliance.
              </p>
            </div>
            <div className={styles.imageDiv}>
              <img src={step2} alt="" />
            </div>
          </div>
          <div className={`${styles.sectionDiv} flex-wrap-reverse`}>
            <div className={styles.imageDiv}>
              <img src={step3} alt="" />
            </div>
            <div className={styles.contentLeft}>
              <h3>Step 3</h3>
              <p>
                Explore our collection of meticulously crafted frames or opt to
                take your unframed print to a nearby frame shop of your choice.
                The choice is yours to either embrace our handcrafted frames or
                personalize the framing experience to suit your preferences at a
                local frame shop.
              </p>
            </div>
          </div>
          <h3>Create your own art now!</h3>
          <div className={styles.getStarted}>
            <Link to={"/"}>Get Started</Link>
          </div>
          <div className={`${styles.showGallery}`}>
            <Link className="bg-[#faf7ef]" to={"/dreams"}>Show Gallery</Link>
          </div>
          <div className={styles.gallery}>
            <Gallery images={images} />
          </div>

          <footer className="text-black bg-[#faf7ef]">
            <div className={styles.footerTop}>
              <div>
                <h3>Connect with us</h3>
                <p>Email: hello@dreamsumiai.com</p>
              </div>
              <div>
                <h3>Our Socials</h3>
                <div className="flex">
                  <CiFacebook size={30} />
                  <FaXTwitter
                    size={25}
                    style={{
                      marginLeft: 10,
                    }}
                  />

                  <CiLinkedin
                    size={25}
                    style={{
                      marginLeft: 10,
                    }}
                  />

                  <FaInstagram
                    size={25}
                    style={{
                      marginLeft: 10,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.footerBottom}>
              <p>Copyright {new Date().getFullYear()} &copy; Dream Sumi AI</p>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Howitworks;
