import React, { useContext, useEffect, useState } from "react";
import styles from "./Gallery.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import styled, { keyframes, css } from "styled-components";

const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;

const slideRight = keyframes`
  0% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0%);
  }
`;
const GalleryRight = styled.div`
  display: flex;
  animation: ${(props) =>
    props.isPaused
      ? "none"
      : css`
          ${slideRight} 10s linear infinite
        `};
`;

function Gallery({ images, show, height }) {
  const navigate = useNavigate();
  const {
    setDream,
    setDreamLocation,
    setDreamMode,
    setDreamAction,
    setArtStyle,
  } = useContext(UserContext);
  const [_images, setImages] = useState([]);
  const [isPaused, setIsPaused] = useState(false);

  // Function to pause the slider animation
  const pauseSlider = () => {
    setIsPaused(true);
  };

  // Function to resume the slider animation
  const resumeSlider = () => {
    setIsPaused(false);
  };

  const getImages = async () => {
    try {
      const res = await axios.get(`${admin_url}/get_images`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setImages(res.data.images);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getImages();
  }, []);

  const chooseImage = (item) => {
    window.localStorage.setItem("selectedImage", item.image);
    localStorage.setItem("AspectRatio", item.aspectRatio);
    navigate("/print-options");
  };

  const setProperty = (item) => {
    if (show === "location") {
      setDreamLocation(item.location);
    }

    if (show === "action") {
      setDreamAction(item.action);
    }
    if (show === "mood") {
      setDreamMode(item.mood);
    }
    if (show === "subject") {
      setDream(item.subject);
    }
    if (show === "artStyle") {
      setArtStyle(item.artStyle);
    }
  };

  return (
    <div className={styles.galleryContainer}>
      {show ? (
        <GalleryRight
        isPaused={isPaused}
        onMouseOver={pauseSlider}
        onMouseOut={resumeSlider}
      >
          {_images.map((item, index) => (
            <div
              key={index}
              className={`h-[100px] ${styles.imageContainer} `}
              style={{
                backgroundImage: `url(${
                  item.image.doc ? item.image.doc : item.image
                })`,
                animationDelay: `${index * 0.2}s`,
              }}
              onClick={() => setProperty(item)}
            >
              <div className={styles.overlay}>
                <p className="p-1">{item[`${show}`]}</p>
              </div>
            </div>
          ))}
        </GalleryRight>
      ) : (
        <GalleryRight
          isPaused={isPaused}
          onMouseOver={pauseSlider}
          onMouseOut={resumeSlider}
        >
          {images.map((item, index) => (
            <div
              key={index}
              className={`${styles.imageContainer} sm:h-[${height}px]`}
              style={{
                backgroundImage: `url(${item.image})`,
                animationDelay: `${index * 0.2}s`,
              }}
              onClick={() => chooseImage(item)}
            >
              <div className={styles.overlay}>
                {/* <p>{item.artStyle}</p> */}
              </div>
            </div>
          ))}
        </GalleryRight>
      )}
    </div>
  );
}

export default Gallery;
