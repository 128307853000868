/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from "react";
import styles from "./styles.module.css";
import { FcGoogle } from "react-icons/fc";
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
import { UserContext } from "../../context/UserContext";
import { useDispatch } from "react-redux";
import { google } from "../../store/reducers/auth";
import toast from "react-hot-toast";

const SocialLogin = ({ text }) => {
  const { setUserToken, setAuthenticated, setUserCredit, setSocialProvider } =
    useContext(UserContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const start = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", start);
  });

  const onGoogleSuccess = async (res) => {
    try {
      const { email, googleId } = res.profileObj;
      await dispatch(google({ email, googleId, provider: "GOOGLE" })).then(
        (res) => {
          if (res.meta.requestStatus === "rejected") {
            toast.error(res.payload);
            return;
          } else {
            toast.success(res.payload.message);
            setAuthenticated(true);
            setUserCredit(res.payload.credit);
            setUserToken(res.payload.token);
            setSocialProvider(res.payload.provider);
            window.location.href = "/";
            return;
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onGoogleFailure = (res) => {
    console.log("Failed", res);
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.googleDiv}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Login"
          onSuccess={onGoogleSuccess}
          onFailure={onGoogleFailure}
          cookiePolicy="single_host_origin"
          // redirectUri={process.env.REACT_APP_REDIRECT_URI}
          render={(renderProps) => (
            <button
              className={styles.googleLogin}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <FcGoogle /> <span className="ml-2">{text} with Google</span>
            </button>
          )}
        />
      </div>
    </div>
  );
};

export default SocialLogin;
