import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/user`;
const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;

const initialState = {
  user: null,
};

export const signup = createAsyncThunk(
  "auth/signup",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/signup`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
      }
      return response.data;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${url}/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data) {
      localStorage.setItem(
        "dreamsumiai-user",
        JSON.stringify(response.data.token)
      );
      localStorage.setItem(
        "dreamsumiai-usercredit",
        JSON.stringify(response.data.credit)
      );
    }
    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const adminLogin = createAsyncThunk("auth/admin/login", async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${admin_url}/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data) {
      localStorage.setItem(
        "dreamsumiai-admin",
        JSON.stringify(response.data.token)
      );
    }
    return response.data;
  } catch (error) {
    const message =
      (error.message && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/forgot-password`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
      }
      return response.data;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(`${url}/reset-password`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateDetails = createAsyncThunk(
  "auth/updateDetails",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(`${url}/update`, data, {
        headers: {
          Authorization: `Bearer ${data.userToken}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        localStorage.setItem(
          "dreamsumiai-user",
          JSON.stringify(response.data.token)
        );
      }
      return response.data;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const google = createAsyncThunk(
  "auth/google",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${url}/google`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        localStorage.setItem(
          "dreamsumiai-user",
          JSON.stringify(response.data.token)
        );
        localStorage.setItem(
          "dreamsumiai-usercredit",
          JSON.stringify(response.data.credit)
        );
        localStorage.setItem(
          "dreamsumiai-social-provider",
          JSON.stringify(response.data.provider)
        );
      }
      return response.data;
    } catch (error) {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      localStorage.removeItem("dreamsumiai-user");
      localStorage.removeItem("dreamsumiai-usercredit");
      localStorage.removeItem("ddreamsumiai-social-provider");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload.token;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
