import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Homepage from "./pages/homepage/Homepage";
import Dreams from "./pages/dreams/Dreams";
import Signup from "./pages/Auth/Signup/Signup";
import Login from "./pages/Auth/Login/Login";
import ForgotPassword from "./pages/Auth/Login/ForgotPassword";
import ResetPassword from "./pages/Auth/Login/ResetPassword";
import Howitworks from "./pages/Howitworks/Howitworks";
import Prompts from "./pages/prompts/Prompts";
import RefineImage from "./pages/refineImage/RefineImage";
import SelectedImage from "./pages/selectedImage/SelectedImage";
import PrintOption from "./pages/printOptions/PrintOption";
import Checkout from "./pages/checkout/Checkout";
import OrderComplete from "./pages/orderComplete/OrderComplete";
import Profile from "./pages/profile/Profile";
import Orders from "./pages/orders/Orders";
import OrderDetails from "./pages/orders/OrderDetails";
import PageError from "./pages/pageError/PageError";
import ProtectedRoutes from "./components/protected/ProtectedRoutes";
import AdminProtectedRoutes from "./components/protected/AdminProtectedRoutes";
import VerifyEmail from "./pages/Auth/Login/VerifyEmail";
import AdminLogin from "./pages/admin/auth/AdminLogin";
import Users from "./pages/admin/app/users/Users";
import Images from "./pages/admin/app/images/Images";
import Carousel from "./pages/admin/app/carousel/Carousel";
import Settings from "./pages/admin/app/settings/Settings";
import Admins from "./pages/admin/app/admins/Admins";
import PrintOrder from "./pages/checkout/PrintOrder";
import PaymentProtected from "./components/protected/PaymentProtected";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* UNPROTECTED ROUTES */}
          <Route path="/" element={<Homepage />} />
          <Route path="/dreams" element={<Dreams />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify_email/:token" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/how-it-works" element={<Howitworks />} />
          <Route
            path="/prompts"
            element={
              <ProtectedRoutes>
                <Prompts />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/refine-image"
            element={
              <ProtectedRoutes>
                <RefineImage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/selected-image"
            element={
              <ProtectedRoutes>
                <SelectedImage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/print-options"
            element={
              <ProtectedRoutes>
                <PrintOption />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/print-order"
            element={
              <ProtectedRoutes>
                <PaymentProtected>
                  <PrintOrder />
                </PaymentProtected>
              </ProtectedRoutes>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoutes>
                <Checkout />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/order-complete"
            element={
              <ProtectedRoutes>
                <OrderComplete />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes>
                <Profile />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoutes>
                <Orders />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/order/:id"
            element={
              <ProtectedRoutes>
                <OrderDetails />
              </ProtectedRoutes>
            }
          />

          {/* Admin Routes*/}
          <Route path="/admin" element={<AdminLogin />} />
          <Route
            path="/users"
            element={
              <AdminProtectedRoutes>
                <Users />
              </AdminProtectedRoutes>
            }
          />
          <Route
            path="/admins"
            element={
              <AdminProtectedRoutes>
                <Admins />
              </AdminProtectedRoutes>
            }
          />
          <Route
            path="/images"
            element={
              <AdminProtectedRoutes>
                <Images />
              </AdminProtectedRoutes>
            }
          />
     <Route
            path="/carousel"
            element={
              <AdminProtectedRoutes>
                <Carousel />
              </AdminProtectedRoutes>
            }
          />

          <Route
            path="/settings"
            element={
              <AdminProtectedRoutes>
                <Settings />
              </AdminProtectedRoutes>
            }
          />

          {/* 404 ROUTE */}
          <Route path="*" element={<PageError />} />
        </Routes>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
