import { Navigate } from "react-router-dom";

const AdminProtectedRoutes = ({ children }) => {
  const admin = localStorage.getItem("dreamsumiai-admin");
  if (!admin) {
    return <Navigate to="/admin" replace />;
  }
  return <>{children}</>;
};

export default AdminProtectedRoutes;
