import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../layouts/AdminLayout";
import axios from "axios";
import toast from "react-hot-toast";
const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;
const token = JSON.parse(localStorage.getItem("dreamsumiai-admin"));

const Settings = () => {
  const [settings, setSettings] = useState([]);
  const [charges, setCharge] = useState();
  const [credit, setCredit] = useState();
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  const getSettings = async () => {
    setFetchLoading(true);
    const res = await axios.get(`${admin_url}/get_settings`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    setSettings(res.data.settings[1]);
    setCharge(res.data.settings[1]?.price);
    setCredit(res.data.settings[1]?.credit);
    setFetchLoading(false);
  };

  const update = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      await axios.post(
        `${admin_url}/set_price/${settings._id}`,
        { price: charges },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      await axios.post(
        `${admin_url}/set_credit/${settings._id}`,
        { credit },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      toast.success("Updated successfully");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Updated failed");
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <AdminLayout>
      {fetchLoading ? (
        "Loading..."
      ) : (
        <form onSubmit={update}>
          <p className="text-left font-bold">Set margin (%)</p>
          <div className="flex items-center">
            <div className="border w-[20%] rounded">
              <input
                type="number"
                value={charges}
                className="outline-none w-full p-2 rounded"
                onChange={(e) => setCharge(e.target.value)}
                required
              />
            </div>
          </div>
          <p className="text-left font-bold mt-2">Set Credits</p>
          <div className="flex items-center">
            <div className="border w-[20%] rounded">
              <input
                type="number"
                value={credit}
                className="outline-none w-full p-2 rounded"
                onChange={(e) => setCredit(e.target.value)}
                required
              />
            </div>
          </div>
          <button className="border p-2 rounded hover:bg-black hover:text-white ml-[-1030px] mt-4 w-[20%]">
            {loading ? "Loading..." : <span>Save</span>}
          </button>
        </form>
      )}
    </AdminLayout>
  );
};

export default Settings;
