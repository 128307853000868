import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../layouts/AdminLayout";
import { CiCirclePlus } from "react-icons/ci";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import toast from "react-hot-toast";

const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;
const token = JSON.parse(localStorage.getItem("dreamsumiai-admin"));

const Images = ({ itemsPerPage = 7 }) => {
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [prompt, setPrompt] = useState("");
  const [artStyle, setArtStyle] = useState("");
  const [aspectRatio, setAspectratio] = useState("");
  const [subject, setSubject] = useState("");
  const [location, setLocation] = useState("");
  const [action, setAction] = useState("");
  const [mood, setMood] = useState("");
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [edit, setEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  const handleImage = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleImageUpload = (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("prompt", prompt);
    formData.append("artStyle", artStyle);
    formData.append("aspectRatio", aspectRatio);
    formData.append("subject", subject);
    formData.append("location", location);
    formData.append("action", action);
    formData.append("mood", mood);

    // Send formData to the server using AJAX or fetch API
    // Example using fetch:
    axios
      .post(`${admin_url}/register_image`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async ({ data }) => {
        // Handle the response from the server
        if (data.success) {
          toast.success("Operation successfully");
          await getImages();
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        toast.error("Operation unsuccessfully");
        setLoading(false);
      });
  };
  // Filter the data based on the search term
  const filteredData = images.filter((item) =>
    item.artStyle?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get the current page of data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const addImageToCarousel = async (image) => {
    try {
      const { data } = await axios.post(
        `${admin_url}/add_carousel_image`,
        image,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success(data.message);
        getImages();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle search term change
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when search term changes
  };

  const getImages = async () => {
    setFetchLoading(true);
    const res = await axios.get(`${admin_url}/get_images`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    setImages(res.data.images.reverse());
    setFetchLoading(false);
  };

  const deleteImage = async (id) => {
    try {
      if (loading) return;
      setLoading(true);
      const { data } = await axios.delete(`${admin_url}/delete_image/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (data.success) {
        toast.success(data.message);
        getImages();
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Operation unsuccessful");
      setLoading(false);
    }
  };

  const handleEdit = async (item) => {
    try {
      setEdit(item);
      setPrompt(item.prompt);
      setAction(item.action);
      setArtStyle(item.artStyle);
      setAspectratio(item.aspectRatio);
      setMood(item.mood);
      setLocation(item.location);
      setSubject(item.subject);
      setVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const editImage = async () => {
    try {
      setLoading(true);
      const { data } = await axios.put(
        `${admin_url}/update_image/${edit._id}`,
        {
          prompt,
          artStyle,
          aspectRatio,
          subject,
          action,
          mood,
          location,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        toast.success(data.message);
        getImages();
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Operation unsuccessful");
      setLoading(false);
    }
  };
  useEffect(() => {
    getImages();
  }, []);
  return (
    <AdminLayout>
      <div className="p-4 justify-start">
        <div className="flex items-center justify-between">
          <input
            type="text"
            placeholder="Search Art style"
            value={searchTerm}
            onChange={handleSearchTermChange}
            className="w-[20%] p-2 mb-4 border border-gray-300 rounded outline-none mr-[1000px]"
          />
          <button
            onClick={() => setVisible(true)}
            className="border p-2 mb-4 rounded hover:bg-black hover:text-white ml-[-1030px] mt-4 w-[20%] flex items-center justify-center"
          >
            <CiCirclePlus />
            <span className="ml-2"> Add Image</span>
          </button>
        </div>
        <table className="w-full">
          <thead className="bg-gray-200">
            <tr className="flex justify-between">
              <th className="py-2  w-[15%]">Image</th>
              <th className="py-2  w-[15%]">Prompt</th>
              <th className="py-2  w-[15%]">Art Style</th>
              <th className="py-2  w-[15%]">Carousel</th>
              <th className="py-2  w-[15%]">Action</th>
            </tr>
          </thead>
          {fetchLoading ? (
            "Loading..."
          ) : (
            <tbody>
              {currentData.map((item) => (
                <tr
                  className="flex justify-between items-center hover:bg-[#eee] cursor-pointer"
                  key={item._id}
                >
                  <td className="py-2 w-[15%]">
                    <img
                      src={item.image.doc ? item.image.doc : item.image}
                      alt=""
                      className="ml-5"
                      width={50}
                      height={50}
                      srcset=""
                    />
                  </td>
                  <td className="py-2 w-[15%] ">
                    {item.prompt ? item.prompt.slice(0, 12) : "No prompt"}
                  </td>
                  <td className="py-2 w-[15%]">{item.artStyle}</td>
                  <td
                    className={`py-2 w-[15%] ${item?.inCarousel ? "text-orange-300" : "text-green-500"}`}
                    onClick={() => addImageToCarousel(item)}
                  >
                    {item?.inCarousel ? "Added" : "Add"}
                  </td>
                  <td className="py-2 w-[15%] hover:text-red-400">
                    <button
                      className="ml-2 text-green-500"
                      onClick={() => handleEdit(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="ml-2 text-red-500"
                      onClick={() => deleteImage(item._id)}
                    >
                      {loading ? "Loading..." : "Delete"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div className="flex justify-center w-[100%] overflow-scroll mt-4">
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                disabled={pageNumber === currentPage}
                className={`px-4 rounded py-2 mx-1 cursor-pointer ${
                  pageNumber === currentPage
                    ? " border-1 border-black text-black"
                    : "bg-black opacity-[0.3] text-white"
                }`}
              >
                {pageNumber}
              </button>
            )
          )}
        </div>
        <Dialog
          header={edit ? "Edit Image" : "Add Image"}
          visible={visible}
          className="p-5 rounded h-[70vh] w-[50%] bg-[#eee]"
          onHide={() => {
            setVisible(false);
            setEdit("");
            setPrompt("");
            setAction("");
            setArtStyle("");
            setAspectratio("");
            setMood("");
            setLocation("");
            setSubject("");
          }}
        >
          <form
            onSubmit={(e) => {
              if (edit) {
                editImage();
              } else {
                handleImageUpload(e);
              }
            }}
            className="p-2 mt-3"
          >
            <input
              type="text"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Art style (e.g Pixar)"
              value={artStyle}
              onChange={(e) => setArtStyle(e.target.value)}
              required
            />
            <input
              type="text"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              required
            />
            <select
              value={aspectRatio}
              className="w-[100%] rounded p-2 outline-none mb-4"
              onChange={(e) => setAspectratio(e.target.value)}
            >
              <option value="Square">Square</option>
              <option value="Portrait">Portrait</option>
              <option value="Landscape">Landscape</option>
            </select>

            <input
              type="text"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <input
              type="text"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
            />
            <input
              type="text"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Action"
              value={action}
              onChange={(e) => setAction(e.target.value)}
              required
            />
            <input
              type="text"
              className="w-[100%] rounded p-2 outline-none mb-4"
              placeholder="Mood"
              value={mood}
              onChange={(e) => setMood(e.target.value)}
              required
            />
            {!edit && <p>Image</p>}

            {!edit && (
              <input
                type="file"
                className="w-[100%] rounded p-2 outline-none mb-4"
                accept="image/*"
                onChange={handleImage}
                required
              />
            )}
            <button className="border border-black p-2 rounded hover:bg-black hover:text-white w-[100%]">
              {loading ? "Loading..." : "Save"}
            </button>
          </form>
        </Dialog>
      </div>
    </AdminLayout>
  );
};

export default Images;
