import React from "react";
import mockups from "../../../assets/mockup";

const Thumbnails = ({ gallery, setGallery, mockup, image, aspectRatio }) => {
  return (
    <div className="flex sm:flex-row flex-col w-[15%] sm:w-[100%] sm:justify-around">
      <div
        style={{
          border: gallery === "mockup" ? "solid 2px white" : "none",
          borderRadius: "10px",
        }}
        onClick={() => setGallery("mockup")}
        className="mb-6 h-5 cursor-pointer h-[198px] sm:h-[140px] w-[198px] sm:w-[140px]"
      >
        <img
          src={mockups[`${mockup}`]}
          alt=""
          className="rounded h-full w-full"
        />
      </div>
      <div
        onClick={() => setGallery("image")}
        style={{
          border: gallery === "image" ? "solid 2px white" : "none",
          borderRadius: "10px",
        }}
        className="mb-6 h-5 cursor-pointer h-[198px] sm:h-[140px] w-[198px] sm:w-[140px]"
      >
        <img
          src={image}
          alt=""
          className={
            aspectRatio === "Square"
              ? `h-[198px] sm:h-[140px] w-[198px] sm:w-[140px] rounded`
              : aspectRatio === "Landscape"
              ? "h-[150px] sm:h-[140px] w-full sm:w-[150px] rounded"
              : "h-[250px] sm:h-[140px] w-full sm:w-[100px] rounded"
          }
        />
      </div>
    </div>
  );
};

export default Thumbnails;
