import blackMountSquare30x30 from "./30x30cm-black-2.4mm-Square.png";
import blackNoMountSquare30x30 from "./30x30cm-black-No-Mount-Square.png";
import naturalMountSquare30x30 from "./30x30cm-natural-2.4mm-Square.png";
import naturalNoMountSquare30x30 from "./30x30cm-natural-No-Mount-Square.png";
import whiteMountSquare30x30 from "./30x30cm-white-2.4mm-Square.png";
import whiteNoMountSquare30x30 from "./30x30cm-white-No-Mount-Square.png";

import whiteNoMountSquare40x40 from "./40x40cm-white-No-Mount-Square.png";
import blackMountSquare40x40 from "./40x40cm-black-2.4mm-Square.png";
import blackNoMountSquare40x40 from "./40x40cm-black-No-Mount-Square.png";
import naturalMountSquare40x40 from "./40x40cm-natural-2.4mm-Square.png";
import naturalNoMountSquare40x40 from "./40x40cm-natural-No-Mount-Square.png";
import whiteMountSquare40x40 from "./40x40cm-white-2.4mm-Square.png";

import whiteNoMountSquare50x50 from "./50x50cm-white-No-Mount-Square.png";
import blackMountSquare50x50 from "./50x50cm-black-2.4mm-Square.png";
import blackNoMountSquare50x50 from "./50x50cm-black-No-Mount-Square.png";
import naturalMountSquare50x50 from "./50x50cm-natural-2.4mm-Square.png";
import naturalNoMountSquare50x50 from "./50x50cm-natural-No-Mount-Square.png";
import whiteMountSquare50x50 from "./50x50cm-white-2.4mm-Square.png";

import whiteNoMountSquare60x60 from "./60x60cm-white-No-Mount-Square.png";
import blackMountSquare60x60 from "./60x60cm-black-2.4mm-Square.png";
import blackNoMountSquare60x60 from "./60x60cm-black-No-Mount-Square.png";
import naturalMountSquare60x60 from "./60x60cm-natural-2.4mm-Square.png";
import naturalNoMountSquare60x60 from "./60x60cm-natural-No-Mount-Square.png";
import whiteMountSquare60x60 from "./60x60cm-white-2.4mm-Square.png";

import a1blackMountLandscape from "./A1-black-2.4mm-Landscape.png";
import a1blackMountPortrait from "./A1-black-2.4mm-Portrait.png";
import a1blackNoMountLandscape from "./A1-black-No-Mount-Landscape.png";
import a1blackNoMountPortrait from "./A1-black-No-Mount-Portrait.png";
import a1whiteMountLandscape from "./A1-white-2.4mm-Landscape.png";
import a1whiteMountPortrait from "./A1-white-2.4mm-Portrait.png";
import a1whiteNoMountLandscape from "./A1-white-No-Mount-Landscape.png";
import a1whiteNoMountPortrait from "./A1-white-No-Mount-Portrait.png";
import a1naturalMountLandscape from "./A1-natural-2.4mm-Landscape.png";
import a1naturalMountPortrait from "./A1-Natural-2.4mm-Portrait.png";
import a1naturalNoMountLandscape from "./A1-natural-No-Mount-Landscape.png";
import a1naturalNoMountPortrait from "./A1-natural-No-Mount-Portrait.png";

import a2blackMountLandscape from "./A2-black-2.4mm-Landscape.png";
import a2blackMountPortrait from "./A2-black-2.4mm-Portrait.png";
import a2blackNoMountLandscape from "./A2-black-No-Mount-Landscape.png";
import a2blackNoMountPortrait from "./A2-black-No-Mount-Portrait.png";
import a2whiteMountLandscape from "./A2-white-2.4mm-Landscape.png";
import a2whiteMountPortrait from "./A2-white-2.4mm-Portrait.png";
import a2whiteNoMountLandscape from "./A2-white-No-Mount-Landscape.png";
import a2whiteNoMountPortrait from "./A2-white-No-Mount-Portrait.png";
import a2naturalMountLandscape from "./A2-natural-2.4mm-Landscape.png";
import a2naturalMountPortrait from "./A2-natural-2.4mm-Portrait.png";
import a2naturalNoMountLandscape from "./A2-natural-No-Mount-Landscape.png";
import a2naturalNoMountPortrait from "./A2-natural-No-Mount-Portrait.png";

import a3blackMountLandscape from "./A3-black-2.4mm-Landscape.png";
import a3blackMountPortrait from "./A3-black-2.4mm-Portrait.png";
import a3blackNoMountLandscape from "./A3-black-No-Mount-Landscape.png";
import a3blackNoMountPortrait from "./A3-black-No-Mount-Portrait.png";
import a3whiteMountLandscape from "./A3-white-2.4mm-Landscape.png";
import a3whiteMountPortrait from "./A3-white-2.4mm-Portrait.png";
import a3whiteNoMountLandscape from "./A3-white-No-Mount-Landscape.png";
import a3whiteNoMountPortrait from "./A3-white-No-Mount-Portrait.png";
import a3naturalMountLandscape from "./A3-natural-2.4mm-Landscape.png";
import a3naturalMountPortrait from "./A3-natural-2.4mm-Portrait.png";
import a3naturalNoMountLandscape from "./A3-natural-No-Mount-Landscape.png";
import a3naturalNoMountPortrait from "./A3-natural-No-Mount-Portrait.png";

import a4blackMountLandscape from "./A4-black-2.4mm-Landscape.png";
import a4blackMountPortrait from "./A4-black-2.4mm-Portrait.png";
import a4blackNoMountLandscape from "./A4-black-No-Mount-Landscape.png";
import a4blackNoMountPortrait from "./A4-black-No-Mount-Portrait.png";
import a4whiteMountLandscape from "./A4-white-2.4mm-Landscape.png";
import a4whiteMountPortrait from "./A4-white-2.4mm-Portrait.png";
import a4whiteNoMountLandscape from "./A4-white-No-Mount-Landscape.png";
import a4whiteNoMountPortrait from "./A4-white-No-Mount-Portrait.png";
import a4naturalMountLandscape from "./A4-natural-2.4mm-Landscape.png";
import a4naturalMountPortrait from "./A4-natural-2.4mm-Portrait.png";
import a4naturalNoMountLandscape from "./A4-natural-No-Mount-Landscape.png";
import a4naturalNoMountPortrait from "./A4-natural-No-Mount-Portrait.png";

const mockups = {
  "30x30cm-black-2.4mm-Square": blackMountSquare30x30,
  "30x30cm-black-No-Mount-Square": blackNoMountSquare30x30,
  "30x30cm-natural-2.4mm-Square": naturalMountSquare30x30,
  "30x30cm-natural-No-Mount-Square": naturalNoMountSquare30x30,
  "30x30cm-white-2.4mm-Square": whiteMountSquare30x30,
  "30x30cm-white-No-Mount-Square": whiteNoMountSquare30x30,

  "40x40cm-black-2.4mm-Square": blackMountSquare40x40,
  "40x40cm-black-No-Mount-Square": blackNoMountSquare40x40,
  "40x40cm-natural-2.4mm-Square": naturalMountSquare40x40,
  "40x40cm-natural-No-Mount-Square": naturalNoMountSquare40x40,
  "40x40cm-white-2.4mm-Square": whiteMountSquare40x40,
  "40x40cm-white-No-Mount-Square": whiteNoMountSquare40x40,

  "50x50cm-black-2.4mm-Square": blackMountSquare50x50,
  "50x50cm-black-No-Mount-Square": blackNoMountSquare50x50,
  "50x50cm-natural-2.4mm-Square": naturalMountSquare50x50,
  "50x50cm-natural-No-Mount-Square": naturalNoMountSquare50x50,
  "50x50cm-white-2.4mm-Square": whiteMountSquare50x50,
  "50x50cm-white-No-Mount-Square": whiteNoMountSquare50x50,

  "60x60cm-black-2.4mm-Square": blackMountSquare60x60,
  "60x60cm-black-No-Mount-Square": blackNoMountSquare60x60,
  "60x60cm-natural-2.4mm-Square": naturalMountSquare60x60,
  "60x60cm-natural-No-Mount-Square": naturalNoMountSquare60x60,
  "60x60cm-white-2.4mm-Square": whiteMountSquare60x60,
  "60x60cm-white-No-Mount-Square": whiteNoMountSquare60x60,

  "A1-black-2.4mm-Landscape": a1blackMountLandscape,
  "A1-black-2.4mm-Portrait": a1blackMountPortrait,
  "A1-black-No-Mount-Landscape": a1blackNoMountLandscape,
  "A1-black-No-Mount-Portrait": a1blackNoMountPortrait,
  "A1-white-2.4mm-Landscape": a1whiteMountLandscape,
  "A1-white-2.4mm-Portrait": a1whiteMountPortrait,
  "A1-white-No-Mount-Landscape": a1whiteNoMountLandscape,
  "A1-white-No-Mount-Portrait": a1whiteNoMountPortrait,
  "A1-natural-2.4mm-Landscape": a1naturalMountLandscape,
  "A1-natural-2.4mm-Portrait": a1naturalMountPortrait,
  "A1-natural-No-Mount-Landscape": a1naturalNoMountLandscape,
  "A1-natural-No-Mount-Portrait": a1naturalNoMountPortrait,
  
  "A2-black-2.4mm-Landscape": a2blackMountLandscape,
  "A2-black-2.4mm-Portrait": a2blackMountPortrait,
  "A2-black-No-Mount-Landscape": a2blackNoMountLandscape,
  "A2-black-No-Mount-Portrait": a2blackNoMountPortrait,
  "A2-white-2.4mm-Landscape": a2whiteMountLandscape,
  "A2-white-2.4mm-Portrait": a2whiteMountPortrait,
  "A2-white-No-Mount-Landscape": a2whiteNoMountLandscape,
  "A2-white-No-Mount-Portrait": a2whiteNoMountPortrait,
  "A2-natural-2.4mm-Landscape": a2naturalMountLandscape,
  "A2-natural-2.4mm-Portrait": a2naturalMountPortrait,
  "A2-natural-No-Mount-Landscape": a2naturalNoMountLandscape,
  "A2-natural-No-Mount-Portrait": a2naturalNoMountPortrait,

  "A3-black-2.4mm-Landscape": a3blackMountLandscape,
  "A3-black-2.4mm-Portrait": a3blackMountPortrait,
  "A3-black-No-Mount-Landscape": a3blackNoMountLandscape,
  "A3-black-No-Mount-Portrait": a3blackNoMountPortrait,
  "A3-white-2.4mm-Landscape": a3whiteMountLandscape,
  "A3-white-2.4mm-Portrait": a3whiteMountPortrait,
  "A3-white-No-Mount-Landscape": a3whiteNoMountLandscape,
  "A3-white-No-Mount-Portrait": a3whiteNoMountPortrait,
  "A3-natural-2.4mm-Landscape": a3naturalMountLandscape,
  "A3-natural-2.4mm-Portrait": a3naturalMountPortrait,
  "A3-natural-No-Mount-Landscape": a3naturalNoMountLandscape,
  "A3-natural-No-Mount-Portrait": a3naturalNoMountPortrait,

  "A4-black-2.4mm-Landscape": a4blackMountLandscape,
  "A4-black-2.4mm-Portrait": a4blackMountPortrait,
  "A4-black-No-Mount-Landscape": a4blackNoMountLandscape,
  "A4-black-No-Mount-Portrait": a4blackNoMountPortrait,
  "A4-white-2.4mm-Landscape": a4whiteMountLandscape,
  "A4-white-2.4mm-Portrait": a4whiteMountPortrait,
  "A4-white-No-Mount-Landscape": a4whiteNoMountLandscape,
  "A4-white-No-Mount-Portrait": a4whiteNoMountPortrait,
  "A4-natural-2.4mm-Landscape": a4naturalMountLandscape,
  "A4-natural-2.4mm-Portrait": a4naturalMountPortrait,
  "A4-natural-No-Mount-Landscape": a4naturalNoMountLandscape,
  "A4-natural-No-Mount-Portrait": a4naturalNoMountPortrait
};

export default mockups;
