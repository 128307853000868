import React, { useEffect, useState } from "react";
import AdminLayout from "../../../../layouts/AdminLayout";
import axios from "axios";
const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;
const token = JSON.parse(localStorage.getItem("dreamsumiai-admin"));

const Users = ({ itemsPerPage = 10 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);

  // Filter the data based on the search term
  const filteredData = users.filter((item) =>
    item.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get the current page of data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

// Handle pagination
const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};

  // Handle search term change
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page when search term changes
  };

  const getUsers = async () => {
    setFetchLoading(true);
    const res = await axios.get(`${admin_url}/get_users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    setUsers(res.data.users);
    setFetchLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <AdminLayout>
      <div className="p-4 justify-start">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchTermChange}
          className="w-[20%] p-2 mb-4 border border-gray-300 rounded outline-none mr-[1000px]"
        />
        <table className="w-full">
          <thead className="bg-gray-200">
            <tr className="flex justify-between">
              <th className="py-2 px-2">Email</th>
              <th className="py-2 px-2">Orders</th>
              <th className="py-2 px-2">Email Verified</th>
            </tr>
          </thead>
          {fetchLoading ? (
            "Loading..."
          ) : (
            <tbody>
              {currentData.map((item) => (
                <tr
                  className="flex justify-between hover:bg-[#eee] cursor-pointer"
                  key={item.id}
                >
                  <td className="py-2 px-2 w-[1%]">{item.email}</td>
                  <td className="py-2 px-2">{item?.orders ? item?.orders : 0}</td>
                  <td className="py-2 px-2">
                    {item.emailConfirmed ? "Verified" : "Not Verified"}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (pageNumber) => (
              <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  disabled={pageNumber === currentPage}
                  className={`px-4 rounded py-2 mx-1 cursor-pointer ${
                    pageNumber === currentPage
                      ? " border-1 border-black text-black"
                      : "bg-black opacity-[0.3] text-white"
                  }`}
                >
                  {pageNumber}
                </button>
            )
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Users;
