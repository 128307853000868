import React from "react";

const FrameSize = ({ aspectRatio, size, setSize, setSizing }) => {
  return (
    <div className="mb-3">
      <p className="h6 text-left font-medium text-black sm:ml-[-22px]">
        Frame Size
      </p>
      {aspectRatio === "Square" ? (
        <div className="flex sm:flex-wrap sm:ml-[-30px] w-[130%]">
          <div
            style={{
              border: size === "30x30cm" ? "solid 2px black" : "none",
              borderRadius: "10px",
            }}
            onClick={() => {
              setSize("30x30cm");
              setSizing("12x12 / 30x30cm");
            }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
          >
            <p className="mt-3">12x12</p>
            <p className="">30x30cm</p>
          </div>

          <div
            style={{
              border: size === "40x40cm" ? "solid 2px black" : "none",
              borderRadius: "10px",
            }}
            onClick={() => {
              setSize("40x40cm");
              setSizing("16x16 / 40x40cm");
            }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
          >
            <p className="mt-3">16x16</p>
            <p className="">40x40cm</p>
          </div>

          <div
            style={{
              border: size === "50x50cm" ? "solid 2px black" : "none",
              borderRadius: "10px",
            }}
            onClick={() => {
              setSize("50x50cm");
              setSizing("20x20 / 50x50cm");
            }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
          >
            <p className="mt-3">20x20</p>
            <p className="">50x50cm</p>
          </div>
          <div
            style={{
              border: size === "60x60cm" ? "solid 2px black" : "none",
              borderRadius: "10px",
            }}
            onClick={() => {
              setSize("60x60cm");
              setSizing("24x24 / 60x60cm");
            }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
          >
            <p className="mt-3">24x24</p>
            <p className="">60x60cm</p>
          </div>
        </div>
      ) : (
        <div className="flex sm:flex-wrap sm:ml-[-30px] w-[130%]">
          <div
            style={{
              border: size === "A1" ? "solid 2px black" : "none",
              borderRadius: "10px",
            }}
            onClick={() => {
              setSize("A1");
              setSizing("59.4x84.1cm / 23.4x33.1 A1");
            }}
                   className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[120px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
          >
            <p className="text-lg font-medium">A1</p>
            <p className="mt-[-8px]">59.4x84.1cm</p>
          </div>
          <div
            style={{
              border: size === "A2" ? "solid 2px black" : "none",
              borderRadius: "10px",
            }}
            onClick={() => {
              setSize("A2");
              setSizing("42x59.4cm / 16.5x23.4 A2");
            }}
                   className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[120px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
          >
            <p className="text-lg font-medium">A2</p>
            <p className="mt-[-8px]">42x59.4cm</p>
          </div>
          <div
            style={{
              border: size === "A3" ? "solid 2px black" : "none",
              borderRadius: "10px",
            }}
            onClick={() => {
              setSize("A3");
              setSizing("29.7x42cm / 11.7x16.5 A3");
            }}
                   className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[120px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
          >
            <p className="text-lg font-medium">A3</p>
            <p className="mt-[-8px]">29.7x42cm</p>
          </div>
          <div
            style={{
              border: size === "A4" ? "solid 2px black" : "none",
              borderRadius: "10px",
            }}
            onClick={() => {
              setSize("A4");
              setSizing("21x29.7cm / 8.3x11.7 A4");
            }}
                   className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[120px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
          >
            <p className="text-lg font-medium">A4</p>
            <p className="mt-[-8px]">21x29.7cm</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FrameSize;
