import React from "react";

const Mount = ({ mount, setMount }) => {
  return (
    <div className="mb-3">
      <p className="h6 text-left font-medium text-black sm:ml-[-28px]">
        Border Options
      </p>
      <div className="flex sm:ml-[-30px]">
        <div
          style={{
            border: mount === "No-Mount" ? "solid 2px black" : "solid 1px #ccc",
            borderRadius: "10px",
          }}
          onClick={() => {
            setMount("No-Mount");
          }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
        >
          <img
            src="/assets/no-mount.png"
            className="w-full h-full rounded"
            alt=""
          />
        </div>
        <div
          style={{
            border: mount === "2.4mm" ? "solid 2px black" : "solid 1px #ccc",
            borderRadius: "10px",
          }}
          onClick={() => {
            setMount("2.4mm");
          }}
          className="text-black text-xs bg-white h-[90px]  sm:h-[70px] w-[100px] md:w-[80px] rounded cursor-pointer sm:mb-3 mr-3 flex flex-col justify-center items-center"
        >
          <img
            src="/assets/mount.png"
            className="w-full h-full rounded"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Mount;
