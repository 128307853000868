/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styles from "./Dreams.module.css";
import Navbar from "../../components/Navbar/Navbar";
import Gallery from "../../components/Gallery/Gallery";
import GalleryLeft from "../../components/Gallery/GalleryLeft";
import axios from "axios";

const admin_url = `${process.env.REACT_APP_BACKEND}/api/${process.env.REACT_APP_API_VERSION}/admin`;
const token = JSON.parse(localStorage.getItem("dreamsumiai-admin"));

const Dreams = () => {
  const [_images, setImages] = useState([]);
  const [images, setImage] = useState([]);

  function shuffleArray(array) {
    const shuffledArray = [...array]; // Create a copy of the original array

    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Generate a random index

      // Swap elements at indices i and j
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }

    return shuffledArray;
  }

  const getImages = async () => {
    try {
      const res = await axios.get(`${admin_url}/get_carousel`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const shuffledArray = shuffleArray(res.data.images);
      setImages(shuffledArray);
    } catch (error) {
      console.log(error);
    }
  };

  function createEqualLengthArrays(inputArray) {
    const totalLength = inputArray.length;
    const lengthPerArray = Math.floor(totalLength / 4);
    const remainder = totalLength % 4;

    const arrays = [];
    let startIndex = 0;

    for (let i = 0; i < 4; i++) {
      const arrayLength = lengthPerArray + (i < remainder ? 1 : 0);
      const newArray = inputArray.slice(startIndex, startIndex + arrayLength);
      arrays.push(newArray);
      startIndex += arrayLength;
    }
    return arrays;
  }
  useEffect(() => {
    getImages();
  }, []);

  useEffect(() => {
    const images = createEqualLengthArrays(_images);
    setImage(images);
  }, [_images]);
  const mobileHeight = 20000;

  if (!images.length) {
    return <div>No Images to show</div>;
  }
  return (
    <div>
      <Navbar />
      <div className={styles.contentWrapper}>
        <div className={styles.gallery}>
          <div className={styles.galleryDiv}>
            <Gallery height={mobileHeight} images={images[0]} />
          </div>
          <div className={styles.galleryDiv}>
            <GalleryLeft height={mobileHeight} images={images[1]} />
          </div>
          <div className={styles.galleryDiv}>
            <Gallery height={mobileHeight} images={images[2]} />
          </div>
          <div className={styles.galleryDiv}>
            <GalleryLeft height={mobileHeight} images={images[3]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dreams;
