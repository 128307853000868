import React, { useEffect, useState } from "react";
import styles from "./Checkout.module.css";
import { imageStyles } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import MakePayment from "../../components/Checkout/MakePayment";
import UserDetails from "../../components/Checkout/UserDetails";
import Image from "../printOptions/components/Image";

const Checkout = () => {
  const [done, setDone] = useState(false);
  const [artData, setArtData] = useState(null);
  const [gallery] = useState("mockup");

  useEffect(() => {
    let artData = localStorage.getItem("artData");
    setArtData(JSON.parse(artData));
  }, []);

  return (
    <div>
      <Navbar />
      <div className={styles.contentWrapper}>
        <div className="flex mt-[100px] sm:flex-col justify-center w-[80%]">
          <div className="w-[50%] sm:w-[100%]">
            <Image
              width="100%"
              gallery={gallery}
              image={artData?.image}
              mockup={artData?.mockup}
              imageStyles={imageStyles}
              size={artData?.size}
              mount={artData?.mount}
              aspectRatio={artData?.aspectRatio}
            />
          </div>
          <div className="w-[30%] sm:w-[100%] ml-5">
            {done ? (
              <MakePayment setDone={setDone} />
            ) : (
              <UserDetails setDone={setDone} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
